
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store";

// Vendors
import moment from "moment";

// Models
import {AdministratorPlayersProfileDiagnosticsListIndex} from "@/models/app/administrator/players/profile/diagnostics/list/index";
import {AppCommonDiagnosticCategory} from "@/models/app/common/diagnostic-category";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorPlayersProfileDiagnosticsListFormCreateIndexVue from "@/views/app/administrator/players/profile/diagnostics/list/form/create.vue";
import AdministratorPlayersProfileDiagnosticsListFormUpdateIndexVue from "@/views/app/administrator/players/profile/diagnostics/list/form/update.vue";
import AdministratorPlayersProfileDiagnosticsListFormRemoveIndexVue from "@/views/app/administrator/players/profile/diagnostics/list/form/remove.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  props: [
    "diagnosticCategories",
  ],
  components: {
    ElRow,
    ElCol,
    AdministratorPlayersProfileDiagnosticsListFormCreateIndexVue,
    AdministratorPlayersProfileDiagnosticsListFormUpdateIndexVue,
    AdministratorPlayersProfileDiagnosticsListFormRemoveIndexVue,
  },
})
export default class AdministratorPlayersProfileDiagnosticsListIndexVue extends Vue {
  isLoading: boolean | null = false;

  formData: any = {
    filter: {
      user_id: "*",
      diagnostic_category_id: "*",
      date: [
        moment(),
        moment().add(14, "days"),
      ],
    },
    organization_id: store.getters.getOrganizationMember.organization_id,
  };

  moment: any = null;

  diagnosticCategories: Array<AppCommonDiagnosticCategory> = [];

  diagnostics: Array<AdministratorPlayersProfileDiagnosticsListIndex> = [];

  async getDiagnostics(formData: any = null): Promise<void> {
    this.isLoading = true;

    if (formData) {
      this.formData = formData;
    }

    await getRequest({
      uri: `/common/diagnostics`,
      formData: this.formData,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.diagnostics = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    this.moment = moment;
    this.formData.filter.user_id = this.$route.params.id;
    await this.getDiagnostics();
  }
}
