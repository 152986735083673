import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03235e2d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-players-profile-diagnostics-list-form-create" }
const _hoisted_2 = { class: "action" }
const _hoisted_3 = { class: "form" }
const _hoisted_4 = {
  key: 0,
  class: "el-form-custom-fields"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Plus = _resolveComponent("Plus")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_time_select = _resolveComponent("el-time-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_button, {
        type: "primary",
        class: "el-button--grey el-button--square",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDialogVisible = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Plus)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_el_dialog, {
      title: "Dodaj",
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_form, {
            model: _ctx.formData,
            rules: _ctx.formDataRules,
            ref: "formComponent",
            onSubmit: _withModifiers(_ctx.validateForm, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "Kategorija",
                prop: "diagnostic_category_id"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.formData.diagnostic_category_id,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.diagnostic_category_id) = $event)),
                    placeholder: "Odaberite",
                    onChange: _ctx.setDiagnosticCategory
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.diagnosticCategories, (diagnosticCategory, index) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          label: `${diagnosticCategory.content.name}`,
                          value: diagnosticCategory.id,
                          key: index
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onChange"])
                ]),
                _: 1
              }),
              (_ctx.diagnosticCategory.content.fields.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_el_form_item, {
                      label: "Datum",
                      prop: "content.date"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          type: "date",
                          modelValue: _ctx.formData.content.date,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.content.date) = $event)),
                          placeholder: "Odaberite",
                          format: "DD.MM.YYYY."
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.diagnosticCategory.content.fields, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "el-form-custom-field",
                        key: index
                      }, [
                        (item.type === 'text')
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 0,
                              label: item.name
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_input, {
                                  modelValue: _ctx.formData.content.fields[index].value,
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.formData.content.fields[index].value) = $event)
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1032, ["label"]))
                          : _createCommentVNode("", true),
                        (item.type === 'textarea')
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 1,
                              label: item.name
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_input, {
                                  type: "textarea",
                                  rows: 8,
                                  modelValue: _ctx.formData.content.fields[index].value,
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.formData.content.fields[index].value) = $event)
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1032, ["label"]))
                          : _createCommentVNode("", true),
                        (item.type === 'number')
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 2,
                              label: item.name
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_input_number, {
                                  modelValue: _ctx.formData.content.fields[index].value,
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.formData.content.fields[index].value) = $event),
                                  min: 0,
                                  precision: 2,
                                  step: 0.1,
                                  "controls-position": "right"
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "step"])
                              ]),
                              _: 2
                            }, 1032, ["label"]))
                          : _createCommentVNode("", true),
                        (item.type === 'select')
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 3,
                              label: item.name
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: _ctx.formData.content.fields[index].value,
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.formData.content.fields[index].value) = $event),
                                  placeholder: "Odaberite"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_option, {
                                      label: "Da",
                                      value: true
                                    }),
                                    _createVNode(_component_el_option, {
                                      label: "Ne",
                                      value: false
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1032, ["label"]))
                          : _createCommentVNode("", true),
                        (item.type === 'range')
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 4,
                              label: item.name
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: _ctx.formData.content.fields[index].value,
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.formData.content.fields[index].value) = $event),
                                  placeholder: "Odaberite"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_option, {
                                      label: "1",
                                      value: 1
                                    }),
                                    _createVNode(_component_el_option, {
                                      label: "2",
                                      value: 2
                                    }),
                                    _createVNode(_component_el_option, {
                                      label: "3",
                                      value: 3
                                    }),
                                    _createVNode(_component_el_option, {
                                      label: "4",
                                      value: 4
                                    }),
                                    _createVNode(_component_el_option, {
                                      label: "5",
                                      value: 5
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1032, ["label"]))
                          : _createCommentVNode("", true),
                        (item.type === 'datepicker')
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 5,
                              label: item.name
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_date_picker, {
                                  type: "date",
                                  modelValue: _ctx.formData.content.fields[index].value,
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.formData.content.fields[index].value) = $event),
                                  placeholder: "Odaberite",
                                  format: "DD.MM.YYYY."
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1032, ["label"]))
                          : _createCommentVNode("", true),
                        (item.type === 'timepicker')
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 6,
                              label: item.name
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_time_select, {
                                  type: "date",
                                  modelValue: _ctx.formData.content.fields[index].value,
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.formData.content.fields[index].value) = $event),
                                  placeholder: "Odaberite",
                                  start: "06:00",
                                  step: "00:15",
                                  end: "23:30"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1032, ["label"]))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    class: "el-button--primary el-button--block",
                    "native-type": "submit",
                    loading: _ctx.isLoading,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validateForm()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Spremi ")
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "onSubmit"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}